import React, { useReducer } from "react";
import axios from "axios";
import WorkoutContext from "./workoutContext";
import workoutReducer from "./workoutReducer";
import {
  GET_WORKOUTS,
  // COPY_WORKOUT_PERIOD,
  // GET_TOTALS,
  // GET_TOTALS_DAYSDUE,
  // IMPORT_WORKOUT,
  ADD_WORKOUT,
  DELETE_WORKOUT,
  UPDATE_WORKOUT,
  SET_CURRENT_WORKOUT,
  CLEAR_CURRENT_WORKOUT,
  SET_DELETE_WORKOUT,
  WORKOUT_ERROR,
  // GET_IMPORT_TEMPLATE,
  // CLEAR_IMPORT_RESULT,
  // GET_REPORTS,
  FILTER_WORKOUT,
  CLEAR_FILTER_WORKOUT,
  CLEAR_ERRORS,
  CLEAR_DELETE_WORKOUT,
} from "../typesWorkouts";
import { DateTime } from "luxon";
import { cleanFilters } from "../../utils/functionsCommon";
import PropTypes from "prop-types";

let debug = 0;
let db = debug >= 1;
let dp = "com.workoutstate";

const WorkoutState = (props) => {
  const initialState = {
    workouts: null,
    current: null,
    filtered: null,
    filterOn: false,
    filters: null,
    deleteId: null,
    error: [],
    import_template: null,
    import_result: null,
    workoutTotals: null,
    totals: null,
    totalsDaysDue: null,
    report_data: {},
    report_adhoc_data: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(workoutReducer, initialState);

  // const copyThePeriod = async (date_period) => {
  //   try {
  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     };

  //     const res = await axios.get(
  //       `/api/workouts/copyperiod/${date_period}`,
  //       config
  //     );

  //     dispatch({
  //       type: COPY_WORKOUT_PERIOD,
  //       payload: res.data,
  //     });
  //   } catch (err) {
  //     dispatch({
  //       type: WORKOUT_ERROR,
  //       payload: err,
  //     });
  //   }
  // };

  // Get Workouts
  const getWorkouts = async (filters, sort) => {
    let lm = dp + ".getWorkouts";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) filters = cleanFilters(filters);

      let res = [];
      res = await axios.get(`/api/workouts/`, {
        params: { filters: filters, sort: sort },
      });

      dispatch({
        type: GET_WORKOUTS,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getWorkouts:end`);
    } catch (err) {
      dispatch({
        type: WORKOUT_ERROR,
        payload: err,
      });
    }
  };

  // // Get Workouts
  // const getTotals = async (filters) => {
  //   let lm = dp + ".getTotals";
  //   try {
  //     if (debug >= 1) console.log(`${lm}:start`);
  //     if (filters) {
  //       for (const property in filters)
  //         if (filters[property] === "" || filters[property] === null)
  //           delete filters[property];
  //     }

  //     let res = [];
  //     res = await axios.get(`/api/workouts/totals`, { params: filters });

  //     dispatch({
  //       type: GET_TOTALS,
  //       payload: res.data,
  //     });

  //     if (debug >= 1) console.log(`${lm}:end`);
  //   } catch (err) {
  //     dispatch({
  //       type: WORKOUT_ERROR,
  //       payload: err,
  //     });
  //   }
  // };

  // // Get getDayDueTotals
  // const getDayDueTotals = async (filters, period) => {
  //   let lm = dp + ".getTotals";
  //   try {
  //     if (debug >= 1) console.log(`${lm}:start`);
  //     if (filters) filters = cleanFilters(filters);

  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     };

  //     if (db) console.log(`${lm}`);
  //     await axios
  //       .post("/api/workouts/daysDueTotals", { period }, config)
  //       .then((res) => {
  //         dispatch({
  //           type: GET_TOTALS_DAYSDUE,
  //           payload: res.data,
  //         });
  //       });

  //     if (debug >= 1) console.log(`${lm}:end`);
  //   } catch (err) {
  //     dispatch({
  //       type: WORKOUT_ERROR,
  //       payload: err,
  //     });
  //   }
  // };

  // // Get Workouts
  // const getReports = async (period, report_name) => {
  //   let lm = dp + ".getReports";
  //   try {
  //     if (db) console.log(`${lm}:period: ${period}`);
  //     if (db) console.log(`${lm}:report_type: ${report_name}`);

  //     const params = new URLSearchParams([
  //       ["period", period],
  //       ["name", report_name],
  //     ]);

  //     if (db) console.log(`${lm}:params: ${params}`);

  //     // let res = [];
  //     await axios.get(`/api/reports`, { params }).then((res) => {
  //       dispatch({
  //         type: GET_REPORTS,
  //         payload: res.data,
  //       });
  //     });
  //   } catch (err) {
  //     dispatch({
  //       type: WORKOUT_ERROR,
  //       payload: err,
  //     });
  //   }
  // };

  // // Get Adhoc Report
  // const getAdhocReport = async (period, report_name) => {
  //   let lm = dp + ".getReports";
  //   // console.log('running');
  //   try {
  //     if (db) console.log(`${lm}:start`);

  //     const params = new URLSearchParams([
  //       ["period", period],
  //       ["name", report_name],
  //     ]);

  //     let res = await axios.get(`/api/reports/adhoc`, { params });
  //     // res should return {data: records, fields: field def}

  //     dispatch({
  //       type: GET_REPORTS,
  //       payload: res.data,
  //     });
  //     if (db) console.log(`${lm}:finish`);
  //   } catch (err) {
  //     dispatch({
  //       type: WORKOUT_ERROR,
  //       payload: err,
  //     });
  //   }
  // };

  // Add Contact
  const addWorkout = async (workout) => {
    let lm = dp + ".addWorkout";
    // not sending token as its send locally
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (db) console.log(`${lm}`);
    console.log(workout);
    await axios
      .post("/api/workouts", workout, config)
      .then((response) => {
        dispatch({
          type: ADD_WORKOUT,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: WORKOUT_ERROR,
          payload: err,
        });
      });
  };

  // Update Contact
  const updateWorkout = async (workout) => {
    let lm = dp + ".updateWorkout: ";
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // as we are being passed in the whole contact, we need to just refer
      //    to the ._id specifically
      const res = await axios.put(
        `/api/workouts/${workout._id}`,
        workout,
        config
      );

      // using res.data instead of the contact passed in as we want to get the
      //   contact item from the db instead of the argument
      dispatch({ type: UPDATE_WORKOUT, payload: res.data });
    } catch (err) {
      if (db) console.log(`${lm}:error: ${err.message}`);
      dispatch({
        type: WORKOUT_ERROR,
        payload: err,
      });
    }
  };

  // // Get Import Template
  // const getImportTemplate = async (options, cp) => {
  //   let lm = (dp = ".getImportTemplate");
  //   try {
  //     if (db) console.log(`${lm}:start`);

  //     const params = new URLSearchParams([
  //       ["estimateDates", options.estimateDates],
  //       ["copyInstallments", options.copyInstallments],
  //     ]);

  //     if (cp === undefined || cp === null || cp === "null")
  //       cp = DateTime.local();

  //     if (db) console.log(`${lm}.currentPeriod:${cp}`);

  //     let res = [];
  //     res = await axios.get(`/api/workouts/importTemplate/${cp}`, {
  //       params,
  //     });

  //     dispatch({
  //       type: GET_IMPORT_TEMPLATE,
  //       payload: res,
  //     });
  //   } catch (err) {
  //     console.log(`${lm}:error: ${err.message}`);
  //     dispatch({
  //       type: WORKOUT_ERROR,
  //       payload: err,
  //     });
  //   }
  // };

  // // Update Contact
  // const importWorkouts = async (import_data, period) => {
  //   let lm = (dp = ".importWorkouts");
  //   try {
  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     };

  //     var theData = [{ data: import_data }];
  //     db = false;
  //     if (db) console.log(`${lm}: start`);
  //     if (db) console.log(`${lm}:import_data: ${import_data}`);
  //     if (db) console.log(`${lm}:period: ${period}`);
  //     if (db) console.log(`${lm}:theData:`);
  //     if (db) console.log(theData);

  //     // as we are being passed in the whole contact, we need to just refer
  //     //    to the ._id specifically
  //     const res = await axios.put(
  //       `/api/workouts/import/${period}`,
  //       theData,
  //       config
  //     );

  //     if (db) console.log(`${lm}:result:`);
  //     if (db) console.log(res);

  //     // using res.data instead of the contact passed in as we want to get the
  //     //   contact item from the db instead of the argument
  //     dispatch({ type: IMPORT_WORKOUT, payload: res.data, period: period });
  //   } catch (err) {
  //     dispatch({
  //       type: WORKOUT_ERROR,
  //       payload: err,
  //     });
  //   }
  // };

  const deleteWorkout = async (id) => {
    try {
      // try registering user with form data and json config
      await axios.delete(`/api/workouts/${id}`);

      dispatch({ type: DELETE_WORKOUT, payload: id });
    } catch (err) {
      dispatch({
        type: WORKOUT_ERROR,
        payload: err,
      });
    }
  };

  // Filter Contacts
  const filterWorkouts = (array) => {
    dispatch({
      type: FILTER_WORKOUT,
      payload: { criteria: array, filterOn: true },
    });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER_WORKOUT, payload: { filterOn: false } });
  };

  const setCurrentWorkout = (workout) => {
    dispatch({ type: SET_CURRENT_WORKOUT, payload: workout });
  };

  const clearCurrentWorkout = () => {
    dispatch({ type: CLEAR_CURRENT_WORKOUT });
  };

  // const clearImportResult = () => {
  //   dispatch({ type: CLEAR_IMPORT_RESULT });
  // };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const setDeleteWorkout = (workout) => {
    dispatch({ type: SET_DELETE_WORKOUT, payload: workout });
  };

  const clearDeleteWorkout = () => {
    dispatch({ type: CLEAR_DELETE_ACCOUNT });
  };

  return (
    <WorkoutContext.Provider
      value={{
        workouts: state.workouts,
        current: state.current,
        filtered: state.filtered,
        filters: state.filters,
        filterOn: state.filterOn,
        deleteId: state.deleteId,
        // import_template: state.import_template,
        // import_result: state.import_result,
        // workoutTotals: state.workoutTotals,
        // totals: state.totals,
        // totalsDaysDue: state.totalsDaysDue,
        // report_data: state.report_data,
        // report_adhoc_data: state.report_adhoc_data,
        error: state.error,
        loading: state.loading,
        // getAdhocReport,
        getWorkouts,
        // getTotals,
        // getDayDueTotals,
        // copyThePeriod,
        // importWorkouts,
        addWorkout,
        updateWorkout,
        deleteWorkout,
        setCurrentWorkout,
        clearCurrentWorkout,
        setDeleteWorkout,
        clearDeleteWorkout,
        // getImportTemplate,
        // clearImportResult,
        // getReports,
        filterWorkouts,
        clearFilter,
        clearErrors,
      }}
    >
      {props.children}
    </WorkoutContext.Provider>
  );
};

WorkoutState.propTypes = {
  children: PropTypes.object,
};

export default WorkoutState;
