import React, { Fragment, useContext, useEffect, useState } from "react";
import SessionContext from "../../context/session/sessionContext";
import SessionHeader from "./SessionHeader";
import ExerciseItem from "./ExerciseItem";
import { reOrder } from "./sessionFunctions";
import { sessionUpdate } from "./sessionUpdateHandler";
import SessionList from "./SessionList";
import { addSessionButton } from "./Buttons";

const Session = () => {
  const sessionContext = useContext(SessionContext);
  const {
    current,
    getSession,
    setCurrentSession,
    clearCurrentSession,
    addSession,
    updateSession,
    deleteSessions,
  } = sessionContext;

  // used for session edit of individual session
  const [session, setSession] = useState(current);
  const [edit, setEdit] = useState(false);

  // used for session edit of session list
  const [sessions, setSessions] = useState([]);
  const [editSessions, setEditSessions] = useState(false);

  useEffect(() => {
    // get active session being viewed
    let activeSession = localStorage.getItem("session");
    if (activeSession) {
      if (!session && !current) {
        getSession(activeSession);
      }
    }
    setSession(current);
  }, [current]);

  const buttonAction = (args) => {
    const action = args.action;
    const e = args.e;

    // individual session handling
    if (["add-session", "clone-session"].includes(action)) {
      addSession(e.target.value);
    }
    if (action === "open") setActiveSession(e.session);
    if (action === "save") updateSession(session);
    if (action === "edit") setEdit(!edit);
    if (action === "close") clearTheSession();

    // multiple session handling
    if (action === "edit-sessions") setEditSessions(!editSessions);
    if (action === "close-sessions") clearTheSessions();
    if (action === "select-session") selectSession(e);
    if (action === "delete-sessions") deleteTheSessions(sessions);

    if (["add", "clone", "delete", "update", "order"].includes(action)) {
      let newSesh = sessionUpdate(args.e, session);
      setSession(newSesh);
    }

    if (action === "reorder") {
      let newExs = reOrder(args.e, session);
      setSession({ ...session, exercises: newExs });
    }
  };

  const setActiveSession = (session) => {
    localStorage.setItem("session", session._id);
    setCurrentSession(session);
  };

  const selectSession = (e) => {
    let selected = e.selected;
    let id = e.id;
    let newSessions = [...sessions];

    if (!selected) {
      newSessions.push(id);
    } else {
      const index = newSessions.indexOf(id);
      if (index > -1) newSessions.splice(index, 1);
    }
    setSessions(newSessions);
  };

  const deleteTheSessions = (sessions) => {
    deleteSessions(sessions);
  };

  // used for session edit of session list
  const clearTheSessions = () => {
    setEditSessions(null);
    setSessions([]);
  };

  // used for session edit of individual session
  const clearTheSession = () => {
    setSession(null);
    localStorage.removeItem("session");
    clearCurrentSession();
  };

  if (!session)
    return (
      <SessionList
        sessionsSelected={sessions}
        buttonFunction={buttonAction}
        edit={editSessions}
      />
    );

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 m-0 p-0">
            <div className="d-flex flex-column vh-100 overflow-hidden m-0 p-0">
              <SessionHeader
                session={session}
                buttonFunction={buttonAction}
                edit={edit}
                buttonText="back"
                idx={0}
                exerciseLength={session?.exercises?.length || 0}
                key={"session-header"}
              />
              <div className="h-100 scrollable overflow-auto">
                {session?.exercises?.length
                  ? session.exercises.map((exercise, idx) => (
                      <ExerciseItem
                        exercise={exercise}
                        ctlId={"exercise." + idx}
                        idx={idx}
                        updateFunction={buttonAction}
                        orderFunction={buttonAction}
                        key={"exercise." + idx}
                        edit={edit}
                      />
                    ))
                  : "Add Exercise"}
                <div className="row p-2" key={"add-exercise-r1"}>
                  <div className="col-12" key={"add-exercise-r1c1"}>
                    {addSessionButton(buttonAction)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Session;
