import {
  GET_WORKOUTS,
  ADD_WORKOUT,
  UPDATE_WORKOUT,
  DELETE_WORKOUT,
  SET_CURRENT_WORKOUT,
  CLEAR_CURRENT_WORKOUT,
  WORKOUT_ERROR,
  CLEAR_ERRORS,
  SET_DELETE_WORKOUT,
  CLEAR_DELETE_WORKOUT,
} from "../typesWorkouts";

import { isArray } from "../../utils/functionsCommon";
import { updateItemArray } from "../../utils/functionsState";

let idSet;

const deleteItemFromArray = (itemArray, itemToDeleteId) =>
  itemArray.filter((record) => record._id !== itemToDeleteId);

const workoutReducer = (state, action) => {
  switch (action.type) {
    case GET_WORKOUTS:
      idSet =
        state.filterOn && state.filtered
          ? state.filtered.map((item) => item._id)
          : [];

      return {
        ...state,
        workouts: action.payload,
        filtered:
          state.filtered && state.filterOn && action?.payload?.length
            ? action.payload.filter((item) => idSet.includes(item._id))
            : [],
        loading: false,
      };

    case ADD_WORKOUT:
      return {
        ...state,
        workouts: [action.payload, ...state.workouts],
        loading: false,
      };

    case UPDATE_WORKOUT:
      return {
        ...state,
        workouts: updateItemArray(state.workouts, action.payload, "_id"),
        filtered:
          state.filtered && state.filterOn
            ? updateItemArray(state.filtered, action.payload, "_id")
            : false,
        loading: false,
      };

    case DELETE_WORKOUT:
      return {
        ...state,
        workouts: deleteItemFromArray(state.workouts, action.payload),
        filtered:
          state.filtered && state.filterOn
            ? deleteItemFromArray(state.filtered, action.payload)
            : false,
        loading: false,
      };

    case SET_CURRENT_WORKOUT:
      return {
        ...state,
        current: action.payload,
      };

    case SET_DELETE_WORKOUT:
      return {
        ...state,
        deleteId: action.payload,
      };

    case CLEAR_DELETE_WORKOUT:
      return {
        ...state,
        deleteId: null,
      };

    case WORKOUT_ERROR:
      return {
        ...state,
        error: isArray(state.error)
          ? [action.payload, ...state.error]
          : [action.payload],
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default workoutReducer;
