import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Alerts from "./components/layout/Alerts";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/routing/PrivateRoute";
import Workouts from "./components/pages/Workouts";

import AlertState from "./context/alert/AlertState";
import AuthState from "./context/auth/AuthState";
import WorkoutState from "./context/workout/WorkoutState";
import SessionState from "./context/session/SessionState";

import setAuthToken from "./utils/setAuthToken";

import "./App.css";
import "./table.css";
import SessionAlerts from "./components/sessions/SessionAlerts";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const App = () => {
  return (
    <AuthState>
      <WorkoutState>
        <SessionState>
          <AlertState>
            <Router>
              <Fragment>
                <Navbar
                  title="Gym Workouts"
                  icon="fas fa-dumbbell white-text"
                />
                <div className="container">
                  <Alerts />
                  <SessionAlerts />
                  <Switch>
                    <PrivateRoute exact path="/" component={Workouts} />
                    <PrivateRoute exact path="/workouts" component={Workouts} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/login" component={Login} />
                  </Switch>
                </div>
              </Fragment>
            </Router>
          </AlertState>
        </SessionState>
      </WorkoutState>
    </AuthState>
  );
};

export default App;
