// WORKOUTS CONSTS
export const GET_WORKOUTS = "GET_WORKOUTS";
export const GET_WORKOUT_TOTALS = "GET_WORKOUT_TOTALS";
export const GET_TOTALS_DAYSDUE = "GET_TOTALS_DAYSDUE";
export const GET_TOTALS = "GET_TOTALS";
export const ADD_WORKOUT = "ADD_WORKOUT";
export const DELETE_WORKOUT = "DELETE_WORKOUT";
export const SET_CURRENT_WORKOUT = "SET_CURRENT_WORKOUT";
export const CLEAR_CURRENT_WORKOUT = "CLEAR_CURRENT_WORKOUT";
export const SET_DELETE_WORKOUT = "SET_DELETE_WORKOUT";
export const CLEAR_DELETE_WORKOUT = "CLEAR_DELETE_WORKOUT";
export const UPDATE_WORKOUT = "UPDATE_WORKOUT";
export const FILTER_WORKOUT = "FILTER_WORKOUT";
export const CLEAR_FILTER_WORKOUT = "CLEAR_FILTER_WORKOUT";
export const WORKOUT_ERROR = "WORKOUT_ERROR";
export const IMPORT_WORKOUT = "IMPORT_WORKOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
