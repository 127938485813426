import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import {
  cloneButton,
  editSaveButtons,
  input,
  openCloseButton,
  selectSessionBox,
  showHideAll,
} from "./Buttons";

const SessionHeader = ({
  session,
  buttonFunction,
  edit,
  selected,
  buttonText,
  exerciseLength,
  idx,
}) => {
  let k = "session." + idx;

  const fromISOToFmt = (date, format) => {
    if (!date) return "";
    let newDate = DateTime.fromISO(date);
    return !newDate.isValid ? "" : newDate.toFormat(format);
  };

  let sessionDate = edit
    ? fromISOToFmt(session?.date, "yyyy-MM-dd")
    : fromISOToFmt(session?.date, "dd MMM yy");
  let isSessionList = buttonText === "forward";

  return (
    <Fragment>
      <div className="row py-2 m-0" key={k + `-r1`}>
        <div className="col-3 px-1 my-auto" key={k + `-c-date`}>
          {input(sessionDate, "session-date.0", "date", buttonFunction, edit)}
        </div>
        <div className="col-6 px-1 text-center my-auto" key={k + `-c-name`}>
          <span className="h5">
            {input(session?.name || "", "name.0", "text", buttonFunction, edit)}
          </span>
        </div>
        <div
          className="col-3 px-1 text-end align-middle my-auto"
          key={k + `-c-butt`}
        >
          {!edit || !isSessionList
            ? ""
            : selectSessionBox(session._id, selected, buttonFunction)}
          {isSessionList ? cloneButton(buttonFunction, session) : ""}
          {openCloseButton(buttonFunction, session, buttonText)}
        </div>
      </div>
      <div className="row px-2 g-0" key={k + `-r2`}>
        <div className="col col-12 text-end" key={k + `r2-c1-butt`}>
          {exerciseLength > 0 && showHideAll(exerciseLength)}
          {isSessionList ? "" : editSaveButtons(buttonFunction, session, edit)}
        </div>
      </div>
    </Fragment>
  );
};

SessionHeader.propTypes = {
  session: PropTypes.object,
  buttonFunction: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  selected: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  exerciseLength: PropTypes.number,
  idx: PropTypes.number.isRequired,
};
export default SessionHeader;
