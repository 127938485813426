import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SetItem from "./SetItem";
import { input, showHideButton, exerciseItemButtons } from "./Buttons";

const ExerciseItem = ({ exercise, updateFunction, edit, ctlId, idx }) => {
  let k = ctlId;

  // const input = (value, name, type) => {
  //   let k = ctlId + "." + name + ".0";
  //   if (!edit) return value;
  //   return (
  //     <InputField
  //       name={k}
  //       inputId={k}
  //       value={value}
  //       handlerFunc={updateFunction}
  //       type={type}
  //       key={k}
  //     />
  //   );
  // };

  return (
    <Fragment>
      <div className="row p-2 border-bottom bg-primary" key={k + ".r1"}>
        <div className="col-6 my-auto text-start" key={k + ".r1c1"}>
          {showHideButton(
            k,
            exercise?.name?.name || "Exercise " + idx,
            true,
            "h4"
          )}
        </div>
        <div className="col-6 my-auto text-end" key={k + ".r1c2"}>
          {exerciseItemButtons(
            updateFunction,
            k,
            ctlId,
            idx,
            exercise?.sets?.length || 0
          )}
        </div>
      </div>
      <div
        className="container m-0 p-0 show collapse collapseAll"
        id={`collapse-${k.replace(".", "-")}`}
        key={k + ".exercises"}
      >
        <div className={`row p-2`} key={k + ".r2 "}>
          <div className="col-12" key={k + ".r2c1"}>
            {exercise?.sets?.length
              ? exercise.sets.map((set, sIdx) => {
                  let setId = ctlId + ".set." + sIdx;
                  return (
                    <SetItem
                      set={set}
                      updateFunction={updateFunction}
                      idx={sIdx}
                      setId={setId}
                      key={setId}
                      edit={edit}
                    />
                  );
                })
              : "Add Set"}
          </div>
        </div>
        <div className={`row p-2 border-bottom`} key={k + ".r3"}>
          <div className="col-6" key={k + ".r3c1"}>
            {input(
              exercise?.object || "cable",
              "object",
              "text",
              updateFunction,
              edit
            )}
            {/* {input(exercise?.object || "cable", "object", "text")} */}
          </div>
          <div className="col-6" key={k + ".r3c2"}>
            {input(
              exercise?.type || "normal set",
              "type",
              "text",
              updateFunction,
              edit
            )}
          </div>
        </div>
        <div className={`row p-2 border-bottom`} key={k + ".r4"}>
          <div className="col-12" key={k + ".r4c1"}>
            {input(
              exercise?.comments || "comments",
              "comments",
              "text",
              updateFunction,
              edit
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ExerciseItem.propTypes = {
  exercise: PropTypes.object.isRequired,
  updateFunction: PropTypes.func.isRequired,
  ctlId: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  edit: PropTypes.bool.isRequired,
};
export default ExerciseItem;
