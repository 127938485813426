import React from "react";
import PropTypes from "prop-types";
import { formatCtlDate } from "../../utils/functionsCommon";

const InputField = ({ name, inputId, value, handlerFunc, type }) => {
  if (type === "date") {
    value = type === "date" && value ? formatCtlDate(value) : value;
  }

  return (
    <input
      type={type}
      className="form-control"
      placeholder={name}
      aria-label={name}
      aria-describedby="basic-addon1"
      name={inputId}
      key={inputId}
      value={value}
      onChange={(e) => handlerFunc({ action: "update", e: e }, inputId)}
    />
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  inputId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handlerFunc: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
export default InputField;
