import React, { useEffect, useContext } from "react";
import SessionHome from "../sessions/SessionHome";
import AuthContext from "../../context/auth/authContext";

const Workouts = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();

    // eslint-disable-next-line
  }, []);

  return <SessionHome />;
};

export default Workouts;
