import React, { Fragment } from "react";
import GIconButton from "../common/GIconButton";
import ShowHideButton from "../common/ShowHideButton";
import InputField from "../common/InputField";

export const addSessionButton = (buttonFunction) => (
  <GIconButton
    func={buttonFunction}
    arg={{
      action: "add-session",
      e: { target: { name: "session", value: {} } },
    }}
    k={"btnAddSession"}
    icon={"add_circle"}
  />
);

export const cloneButton = (buttonFunction, session) => (
  <GIconButton
    func={buttonFunction}
    icon={"file_copy"}
    arg={{
      action: "clone-session",
      e: { target: { name: "clone", value: session._id } },
    }}
    k={"btnClone"}
  />
);

export const openCloseButton = (buttonFunction, session, buttonText) => (
  <GIconButton
    func={buttonFunction}
    icon={"arrow_" + buttonText}
    arg={{
      action: buttonText === "forward" ? "open" : "close",
      e: { session: session },
    }}
    k={"btnOpenClose"}
  />
);

export const editSaveButtons = (buttonFunction, session, edit) => {
  if (!edit && buttonFunction)
    return editCancelButton(buttonFunction, "", edit);
  if (edit && buttonFunction)
    return (
      <Fragment>
        {saveButton(buttonFunction, session)}{" "}
        {editCancelButton(buttonFunction, "", edit)}
      </Fragment>
    );
  return "";
};

export const saveButton = (buttonFunction, session) => (
  <GIconButton
    func={buttonFunction}
    icon={"save"}
    arg={{ action: "save", session: session }}
    k={"btnSave"}
  />
);

export const editCancelButton = (buttonFunction, what, edit) => {
  let action = "edit" + (what ? "-" + what : "");
  return (
    <GIconButton
      func={buttonFunction}
      icon={!edit ? "edit" : "close"}
      arg={{ action }}
      k={"btnEdit"}
    />
  );
};

export const cancelButton = (buttonFunction) => (
  <GIconButton
    func={buttonFunction}
    icon={"close"}
    arg={{ action: "edit" }}
    k={"btnEdit"}
  />
);

export const deleteButton = (buttonFunction) => (
  <GIconButton
    func={buttonFunction}
    icon={"delete"}
    arg={{ action: "delete-sessions" }}
    k={"btnDelete"}
  />
);

export const showHideAll = (exerciseLength) => {
  let exerciseList = Array(exerciseLength - 1)
    .fill(0)
    .map((id) => "exercise." + id + " ");
  return (
    <ShowHideButton
      toggle={"collapse"}
      controls={exerciseList.join(" ")}
      target={".collapseAll"}
      icon={"visibility"}
      isText={false}
      k={"showAll"}
      show={true}
    />
  );
};

export const showHideButton = (k, icon, isText, addClass) => (
  <ShowHideButton
    toggle={"collapse"}
    target={`#collapse-${k.replace(".", "-")}`}
    icon={icon}
    isText={isText}
    controls={"collapse"}
    k={k}
    show={true}
    classes={"text-white " + addClass}
  />
);
// export const showHideButton = (k) => (
//   <ShowHideButton
//     toggle={"collapse"}
//     target={`#collapse-${k.replace(".", "-")}`}
//     icon={"visibility"}
//     isText={false}
//     controls={"collapse"}
//     k={k}
//     show={true}
//     classes={"text-white"}
//   />
// );

export const input = (value, name, type, buttonFunction, edit) =>
  !edit ? (
    value
  ) : (
    <InputField
      name={name}
      inputId={name}
      value={value}
      handlerFunc={buttonFunction}
      type={type}
      key={name}
    />
  );

export const selectSessionBox = (id, selected, buttonFunction) => {
  return (
    <input
      className="form-check-input  align-middle"
      type="checkbox"
      id="inlineCheckbox1"
      value="option1"
      onChange={() =>
        buttonFunction({
          e: { id: id, selected: selected },
          action: "select-session",
        })
      }
      checked={selected}
    />
  );
};

const getE = (ctlId, value, other) => {
  return { target: { name: ctlId, value: value, ...other } };
};

export const linkButton = (defaultOpts, ctlId, k, link) => (
  <GIconButton
    {...defaultOpts}
    icon={link ? "link" : "link_off"}
    arg={{
      action: "update",
      e: getE(ctlId, "link-all", { link }),
    }}
    k={k + `-btn-${link ? "" : "un"}linkall-set`}
  />
);

export const reorderButton = (defaultOpts, ctlId, k, up, size, idx) => (
  <GIconButton
    {...defaultOpts}
    icon={"arrow_" + (up ? "upward" : "downward")}
    arg={{
      action: "reorder",
      e: getE(ctlId, up ? -1 : 1),
    }}
    k={k + `-btn-moveUp`}
    disabled={up ? idx === 0 : idx === size - 1} // to be fixed, bottom down arrow still shows, should be greyed out as it's the final row
  />
);

export const addExerciseButton = (defaultOpts, ctlId, k) => (
  <GIconButton
    {...defaultOpts}
    arg={{
      action: "update",
      e: getE(ctlId, "add-set", { link: true }),
    }}
    // arg={ctlId}
    icon={"add_circle"}
    k={k + `-c-btn-add-set`}
  />
);

export const exerciseItemButtons = (updateFunction, k, ctlId, idx, size) => {
  let defaultOpts = { func: updateFunction, classes: `text-white` };
  return (
    <Fragment>
      {showHideButton(k, "visibility", false, "")}
      {linkButton(defaultOpts, ctlId, k, true)}
      {linkButton(defaultOpts, ctlId, k, false)}
      {reorderButton(defaultOpts, ctlId, k, true, size, idx)}
      {reorderButton(defaultOpts, ctlId, k, false, size, idx)}
      {addExerciseButton(defaultOpts, ctlId, k)}
    </Fragment>
  );
};
