import React, { Fragment } from "react";
import PropTypes from "prop-types";
import GIconButton from "../common/GIconButton";
import InputField from "../common/InputField";

const SetItem = ({ set, updateFunction, edit, setId, idx }) => {
  let k = idx;

  const deleteButton = () => (
    <GIconButton
      func={updateFunction}
      icon={"delete"}
      arg={{
        action: "delete",
        e: { target: { name: setId, value: "delete-set" } },
      }}
      k={k + `-btn-add-set`}
    />
  );

  const linkButton = () => (
    <GIconButton
      func={updateFunction}
      icon={set?.link ? "link" : "link_off"}
      arg={{
        action: "update",
        e: { target: { name: setId, value: "link-set" } },
      }}
      k={k + `-btn-link-set`}
    />
  );

  let ctls = ["reps", "weight", "unit"];
  return (
    <Fragment>
      <div className="row py-1 border-bottom " key={k + "-exercise-r1"}>
        <div className="col-2 my-auto" key={k + "-exercise-r1c1"}>
          {set.no}.
        </div>
        {ctls.map((ctl, cIdx) => {
          let inputId = setId + "." + ctl + "." + cIdx;
          return (
            <div className="col-2 my-auto" key={k + "-exercise-r2c2" + cIdx}>
              {!edit ? (
                set[ctl]
              ) : (
                <InputField
                  name={ctl}
                  inputId={inputId}
                  value={set[ctl]}
                  handlerFunc={updateFunction}
                  type={"text"}
                />
              )}
            </div>
          );
        })}
        <div className="col-4 text-end" key={k + "-exercise-r1c3"}>
          {deleteButton()} {linkButton()}
        </div>
      </div>
    </Fragment>
  );
};

SetItem.propTypes = {
  set: PropTypes.object.isRequired,
  updateFunction: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  setId: PropTypes.string.isRequired,
  edit: PropTypes.bool,
};
export default SetItem;
