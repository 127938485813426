import React, { useReducer } from "react";
import axios from "axios";
import SessionContext from "./sessionContext";
import sessionReducer from "./sessionReducer";
import { v4 as uuid } from "uuid";

import {
  GET_SESSION,
  GET_SESSIONS,
  GET_SESSION_COUNT,
  ADD_SESSION,
  DELETE_SESSION,
  UPDATE_SESSION,
  SET_CURRENT_SESSION,
  CLEAR_CURRENT_SESSION,
  SET_DELETE_SESSION,
  SESSION_ERROR,
  FILTER_SESSION,
  CLEAR_FILTER_SESSION,
  CLEAR_ERRORS,
  CLEAR_DELETE_SESSION,
  SET_SESSION_ALERT,
  REMOVE_SESSION_ALERT,
  REMOVE_ALL_SESSION_ALERTS,
  DELETE_SESSIONS,
} from "../typesSessions";
import { cleanFilters } from "../../utils/functionsCommon";
import PropTypes from "prop-types";

let debug = 0;
let db = debug >= 1;
let dp = "com.sessionstate";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const SessionState = (props) => {
  const initialState = {
    sessionAlerts: [],
    sessions: null,
    sessionCount: null,
    current: null,
    filtered: null,
    filterOn: false,
    filters: null,
    deleteId: null,
    error: [],
    import_template: null,
    import_result: null,
    sessionTotals: null,
    totals: null,
    totalsDaysDue: null,
    report_data: {},
    report_adhoc_data: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(sessionReducer, initialState);

  // Set Alert
  const setSessionAlert = (msg, type, timeout = 5000) => {
    const id = uuid();

    dispatch({
      type: SET_SESSION_ALERT,
      payload: { msg, type, id },
    });
    setTimeout(
      () =>
        dispatch({
          type: REMOVE_SESSION_ALERT,
          payload: id,
        }),
      timeout
    );
  };

  // Set Alert
  const clearSessionAlert = () => {
    dispatch({
      type: REMOVE_ALL_SESSION_ALERTS,
    });
  };

  // Get Sessions
  const getSession = async (id) => {
    let lm = dp + ".getSession";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      let res = await axios.get(`/api/sessions/session/${id}`);

      dispatch({
        type: GET_SESSION,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}:end`);
    } catch (err) {
      dispatch({
        type: SESSION_ERROR,
        payload: err,
      });
    }
  };

  // Get Sessions
  const getSessions = async (filters, sort, start) => {
    let lm = dp + ".getSessions";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) filters = cleanFilters(filters);

      let res = [];
      if (!start) start = 1;
      res = await axios.get(`/api/sessions/sessions`, {
        params: { filters: filters, sort: sort, start: start },
      });

      dispatch({
        type: GET_SESSIONS,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getSessions:end`);
    } catch (err) {
      dispatch({
        type: SESSION_ERROR,
        payload: err,
      });
    }
  };

  // Get Sessions
  const getSessionCount = async (filters, sort) => {
    let lm = dp + ".getSessions";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) filters = cleanFilters(filters);

      let res = [];
      res = await axios.get(`/api/sessions/count`, {
        params: { filters: filters, sort: sort },
      });

      dispatch({
        type: GET_SESSION_COUNT,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getSessions:end`);
    } catch (err) {
      dispatch({
        type: SESSION_ERROR,
        payload: err,
      });
    }
  };

  // Add Contact
  const addSession = async (session) => {
    let lm = dp + ".addSession";
    // not sending token as its send locally
    console.log(session);
    if (db) console.log(`${lm}`);
    await axios
      .post("/api/sessions", { _id: session }, config)
      .then((response) => {
        dispatch({
          type: ADD_SESSION,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SESSION_ERROR,
          payload: err,
        });
      });
  };

  // Update Contact
  const updateSession = async (session) => {
    let lm = dp + ".updateSession: ";

    try {
      const res = await axios.put(
        `/api/sessions/${session._id}`,
        session,
        config
      );

      dispatch({ type: UPDATE_SESSION, payload: res.data });
      setSessionAlert("Data Saved", "success");
    } catch (err) {
      if (db) console.log(`${lm}:error: ${err.message}`);
      dispatch({
        type: SESSION_ERROR,
        payload: err,
      });
      setSessionAlert("Error Saving Data", "danger");
    }
  };

  const deleteSession = async (id) => {
    try {
      // try registering user with form data and json config
      await axios
        .post("/api/sessions/deleteSession", { id: id }, config)
        .then((response) => {
          dispatch({
            type: DELETE_SESSION,
            payload: response.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: SESSION_ERROR,
            payload: err,
          });
        });

      // dispatch({ type: DELETE_SESSION, payload: id });
    } catch (err) {
      dispatch({
        type: SESSION_ERROR,
        payload: err,
      });
    }
  };

  const deleteSessions = async (deleteIds) => {
    try {
      await axios
        .post("/api/sessions/delete", { ids: deleteIds }, config)
        .then((response) => {
          dispatch({
            type: DELETE_SESSIONS,
            payload: response.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: SESSION_ERROR,
            payload: err,
          });
        });
    } catch (err) {
      dispatch({
        type: SESSION_ERROR,
        payload: err,
      });
    }
  };

  // Filter Contacts
  const filterSessions = (array) => {
    dispatch({
      type: FILTER_SESSION,
      payload: { criteria: array, filterOn: true },
    });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER_SESSION, payload: { filterOn: false } });
  };

  const setCurrentSession = (session) => {
    dispatch({ type: SET_CURRENT_SESSION, payload: session });
  };

  const clearCurrentSession = () => {
    dispatch({ type: CLEAR_CURRENT_SESSION });
  };

  // const clearImportResult = () => {
  //   dispatch({ type: CLEAR_IMPORT_RESULT });
  // };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const setDeleteSession = (session) => {
    dispatch({ type: SET_DELETE_SESSION, payload: session });
  };

  const clearDeleteSession = () => {
    dispatch({ type: CLEAR_DELETE_ACCOUNT });
  };

  return (
    <SessionContext.Provider
      value={{
        sessionAlerts: state.sessionAlerts,
        sessions: state.sessions,
        sessionCount: state.sessionCount,
        current: state.current,
        filtered: state.filtered,
        filters: state.filters,
        filterOn: state.filterOn,
        deleteId: state.deleteId,
        error: state.error,
        loading: state.loading,
        setSessionAlert,
        clearSessionAlert,
        getSession,
        getSessions,
        getSessionCount,
        addSession,
        updateSession,
        deleteSession,
        deleteSessions,
        setCurrentSession,
        clearCurrentSession,
        setDeleteSession,
        clearDeleteSession,
        filterSessions,
        clearFilter,
        clearErrors,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

SessionState.propTypes = {
  children: PropTypes.object,
};

export default SessionState;
