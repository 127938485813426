import React from "react";
import PropTypes from "prop-types";

const ShowHideButton = ({
  toggle,
  target,
  controls,
  isText,
  icon,
  k,
  classes,
  show,
}) => (
  <button
    type="button"
    className={"btn btn-sm " + classes}
    key={k}
    data-bs-toggle={toggle}
    data-bs-target={target}
    aria-expanded={show}
    aria-controls={controls || target}
  >
    {isText ? (
      <span className={classes}>{icon}</span>
    ) : (
      <span className="material-symbols-outlined" style={{ fontSize: "18px" }}>
        {icon}
      </span>
    )}
  </button>
);

ShowHideButton.propTypes = {
  toggle: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  controls: PropTypes.string,
  icon: PropTypes.string.isRequired,
  isText: PropTypes.bool,
  k: PropTypes.string.isRequired,
  classes: PropTypes.string,
  show: PropTypes.bool.isRequired,
};

export default ShowHideButton;
