import React from "react";
import PropTypes from "prop-types";

const GIconButton = ({ func, icon, arg, k, classes, disabled }) => {
  return (
    <button
      type="button"
      className={"btn btn-sm " + classes}
      key={k}
      onClick={() => func(arg)}
      disabled={disabled}
    >
      <span className="material-symbols-outlined" style={{ fontSize: "18px" }}>
        {icon}
      </span>
    </button>
  );
};

GIconButton.propTypes = {
  func: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  k: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  arg: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default GIconButton;
