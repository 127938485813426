import React, { Fragment, useContext, useState } from "react";
import SessionContext from "../../context/session/sessionContext";

const Pagingation = () => {
  const sessionContext = useContext(SessionContext);
  const { getSessions, sessionCount } = sessionContext;

  const [currentPage, setCurrentPage] = useState(1);

  let page = currentPage;
  let pageSize = 10;
  let pagesEitherSide = 2;
  let pagesToShow = pagesEitherSide * 2 + 1;
  let maxPages = sessionCount;

  let prePost = 3;
  let start;

  if (page + pagesEitherSide < pagesToShow) {
    start = 1;
  } else if (page + pagesEitherSide > maxPages) {
    start = maxPages - pagesToShow + 1;
  } else {
    start = page - pagesEitherSide;
  }

  const gotoPage = (e, p) => {
    setCurrentPage(p);
    getSessions({ start: p });
  };

  const gotoPageButton = (p, text) => (
    <button
      className="buttonAsLink m-2"
      key={`pagLink-${p}`}
      onClick={(e) => gotoPage(e, p)}
    >
      {text}
    </button>
  );

  const showPages = () => {
    let finish = start + pagesToShow;
    // let pre = page !== 1 ? "... " : "";
    // let post = page !== maxPages ? " ..." : "";
    // let pag = pre;
    // pag += post;
    // pag += pageLink + (p !== finish - 1 ? ", " : "");
    let numbers = [];
    for (let p = start; p < finish; p++) {
      numbers.push(p);
    }
    return (
      <Fragment>
        {gotoPageButton(page - pagesToShow, "...")}
        {numbers.map((p) => gotoPageButton(p, p))}
        {gotoPageButton(page + pagesToShow, "...")}
      </Fragment>
    );
  };

  return <div>{showPages()}</div>;
};

export default Pagingation;
