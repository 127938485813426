export const updateItemArray = (dataArray, newData, idField) =>
  dataArray.map((record) =>
    record?.[idField] && newData?.[idField] && record[idField] === newData._id
      ? newData
      : record
  );

export const setReportData = (reports, newReport) => {
  reports[newReport.name] = {
    fields: newReport.fields,
    data: newReport.data,
  };
  return reports;
};
