// CONTACTS CONSTS
export const GET_CONTACTS = "GET_CONTACTS";
export const ADD_CONTACT = "ADD_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const CLEAR_CONTACTS = "CLEAR_CONTACTS";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const FILTER_CONTACTS = "FILTER_CONTACTS";
export const CONTACT_ERROR = "CONTACT_ERROR";

export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REMOVE_ALL_ALERTS = "REMOVE_ALL_ALERTS";

// BALANCES CONSTS
export const GET_BALANCES = "GET_BALANCES";
export const GET_BALANCE_TOTALS = "GET_BALANCE_TOTALS";
export const GET_TOTALS_DAYSDUE = "GET_TOTALS_DAYSDUE";
export const GET_TOTALS = "GET_TOTALS";
export const ADD_BALANCE = "ADD_BALANCE";
export const DELETE_BALANCE = "DELETE_BALANCE";
export const SET_CURRENT_BALANCE = "SET_CURRENT_BALANCE";
export const CLEAR_CURRENT_BALANCE = "CLEAR_CURRENT_BALANCE";
export const SET_DELETE_BALANCE = "SET_DELETE_BALANCE";
export const CLEAR_DELETE_BALANCE = "CLEAR_DELETE_BALANCE";
export const UPDATE_BALANCE = "UPDATE_BALANCE";
export const FILTER_BALANCE = "FILTER_BALANCE";
export const CLEAR_FILTER_BALANCE = "CLEAR_FILTER_BALANCE";
export const BALANCE_ERROR = "BALANCE_ERROR";

// ACCOUNT CONSTS
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const SET_CURRENT_ACCOUNT = "SET_CURRENT_ACCOUNT";
export const SET_DELETE_ACCOUNT = "SET_DELETE_ACCOUNT";
export const CLEAR_DELETE_ACCOUNT = "CLEAR_DELETE_ACCOUNT";
export const CLEAR_CURRENT_ACCOUNT = "CLEAR_CURRENT_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const FILTER_ACCOUNT = "FILTER_ACCOUNT";
export const CLEAR_FILTER_ACCOUNT = "CLEAR_FILTER_ACCOUNT";
export const ACCOUNT_ERROR = "ACCOUNT_ERROR";

// PERIOD CONSTS
export const COPY_BALANCE_PERIOD = "COPY_BALANCE_PERIOD";
export const CREATE_PERIOD = "CREATE_PERIOD";
export const GET_MAXPERIOD = "GET_MAXPERIOD";
export const GET_ALLPERIODS = "GET_ALLPERIODS";
export const SET_CURRENTPERIOD = "SET_CURRENTPERIOD";
export const CLEAR_CURRENTPERIOD = "CLEAR_CURRENTPERIOD";
export const GET_MISSINGPERIODS = "GET_MISSINGPERIODS";
export const PERIOD_ERROR = "PERIOD_ERROR";

// REPORT CONSTS
export const GET_REPORTS = "GET_REPORTS";
export const GET_ADHOC_REPORT = "GET_ADHOC_REPORT";

// IMPORT CONSTS
export const IMPORT_ACCOUNT = "IMPORT_ACCOUNT";
export const IMPORT_BALANCE = "IMPORT_BALANCE";
export const CLEAR_IMPORT_RESULT = "CLEAR_IMPORT_RESULT";
export const GET_IMPORT_TEMPLATE = "GET_IMPORT_TEMPLATE";

// AUTH CONSTS
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const USER_NOT_LOADED = "USER_NOT_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
